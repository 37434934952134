import React from 'react';
import Grid from '@material-ui/core/Grid';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';

import ListItem from '../UI/ListItem';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <Grid
          container
          direction="row"
          className="kos__footer__columns"
          justify="space-between"
          spacing={3}
        >
          <Grid item xs={12} md={4}>
            <h3 className="kos__footer__title">{'About Kings Sprayers'}</h3>
            <p className="kos__footer__text" style={{ marginBottom: 16 }}>
              {`Kings Sprayers has been building sprayers for more than 30 years.`}
            </p>
            <p className="kos__footer__text">
              {`Serving the Agricultural, Horticultural, Golf & Recreation, Lawn & Landscape, and Pest Control industries, Kings Sprayers offers high quality and reliable sprayers for all your spraying needs.`}
            </p>
          </Grid>

          <Grid item xs={12} md={4}>
            <h3 className="kos__footer__title">
              {'Connect with King Sprayers'}
            </h3>
            <p
              className="kos__footer__text"
              style={{ marginBottom: 16 }}
            >{`Stay in touch with King Sprayers by following us on social media!`}</p>
            <div className="kos__logos">
              <a
                title="facebook"
                href="https://www.facebook.com/KingsSprayers/"
              >
                <FacebookIcon />
              </a>
              <a
                title="Sprayer Depot YouTube"
                href="https://www.youtube.com/user/SprayerDepot"
              >
                <YouTubeIcon />
              </a>
            </div>
            <hr className="kos__footer__hr" />
            <p className="kos__footer__text" style={{ marginBottom: 16 }}>
              {'7800 N. Orange Blossom Trail, Orlando, FL 32810'}
            </p>
            <p className="kos__footer__text" style={{ marginBottom: 16 }}>
              {'Phone: (800) 228-0905 | Fax: (407) 886-1616'}
            </p>
          </Grid>
        </Grid>
        <div className="kos__copyright">
          <span className="kos__footer__text">
            {'Copyright 2020 - Kings Sprayers®  |  All Rights Reserved'}
          </span>
          <img
            src="/img/logo-B&W@2x.png"
            className="kos__footer__img"
            alt="bottom-logo"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
